<template>
  <div>
    <b-table
        ref="messages-table"
        striped
        hover
        responsive
        :items="pagedMessages"
        :fields="fields"
        :sort-by.sync="sort.by"
        :sort-desc.sync="sort.desc"
        :sort-direction="sort.direction"
        :busy="loading"
        @row-clicked="openSideBar"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mx-1"></b-spinner>
          <strong>{{ msg('Loading') }}...</strong>
        </div>
      </template>
      <template #cell(customerName)="data">
        <div>
          <b-badge variant="warning">{{ data.value.toUpperCase() }}</b-badge>
        </div>
      </template>
      <template #cell(lastMessage)="data">
        <div>
          <p v-if="data.value!=null && data.value!==''">{{ data.value }}</p>
          <b-badge v-else variant="danger">No Preview</b-badge>
        </div>
      </template>

      <template #cell(source)="data">
        <div>
  <span :style="{ color: getColor(data.value) }">
    {{ (data.value) }}
  </span></div>
      </template>

      <template #cell(lastMessageDate)="data">
        <div>
          {{ $moment(data.value, 'YYYY-MM-DD HH:mm:ss').format("DD.MM.YYYY HH:mm:ss") }}
        </div>
      </template>


    </b-table>

    <div class="text-center w-100">
      <div class="d-inline-block">
        <b-pagination
            v-model="page.currentPage"
            :total-rows="messages.length"
            :per-page="page.amount"
            first-number
            last-number
            @input="refresh"
        />
      </div>
      <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ messages.length }}</div>
    </div>
    <ContractRentSidebar ref="contractRentSidebar"/>

  </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ContractRentSidebar from "@/components/sidebar/ContractRent/ContractRentSidebar.vue";

export default {
  name: 'messagesTable',
  components: {
    ContractRentSidebar
  },
  data() {
    return {
      messages: [],
      loading: true,

      clients: {},
      sort: {by: 'creationDate', direction: 'desc',},
      page: {amount: 10, currentPage: 1, total: 0},

      //Listeners
      listeners: {
        refresh: () => {
        }
      }
    }
  },
  props: {},
  watch: {},
  created() {
    let $this = this
    this.refresh()
    this.$root.$on('crm::messages-table::refresh', $this.listeners.refresh = () => {
      $this.refresh()
    })
  },
  beforeDestroy() {
    this.$off('crm::messages-table::refresh', this.listeners.refresh)
  },
  computed: {
    ...mapGetters('data', ['getBranchName']),
    pagedMessages() {
      const startIndex = (this.page.currentPage - 1) * this.page.amount;
      const endIndex = startIndex + this.page.amount;
      return this.messages.slice(startIndex, endIndex);
    },
    fields() {
      let $this = this
      let fields = [

        {
          key: 'customerName',
          label: $this.msg('Client'),
          sortable: false
        }, {
          key: 'propertyAddress',
          label: $this.msg('Property'),
          sortable: false
        }
        ,
        {
          key: 'lastMessage',
          label: $this.msg('Message'),
          sortable: false
        }, {
          key: 'source',
          label: $this.msg('Platform'),
          sortable: false
        },
        {
          key: 'lastMessageDate',
          label: $this.msg('Date'),
          sortable: true
        }
      ]
      if (!Array.isArray(this.fieldsFilter) || this.fieldsFilter.length === 0) {
        return fields
      }
      return fields.filter(f => $this.fieldsFilter.includes(f.key))
    },
  },
  methods: {
    ...mapActions('messages', ['getAllMessages', 'setMessagesSeen']),
    ...mapActions('clients', ['getClients']),
    goToContract(message) {
      let $this = this;
      this.setMessagesSeen(message.contractId)
          .then();

      this.$router.push('/real_estate/contracts/rent/' + message.contractId)
    },

    openSideBar(message) {
      this.$refs.contractRentSidebar.showContract(message.contractId)
    },
    getColor(value) {
      switch (value) {
        case 'Whatsapp':
          return '#00c853'; // Green color for Whatsapp
        case 'Booking':
          return 'blue'; // Blue color for Booking
        case 'Airbnb':
          return 'red'; // Red color for Airbnb
        case 'Expedia':
          return 'darkgoldenrod'; // Dark yellow color for Expedia
        default:
          return 'black'; // Default color
      }
    },
    refresh() {
      let $this = this
      this.loading = true;

      this.getAllMessages()
          .then($this.onMessagesRetrieve)
    },
    onMessagesRetrieve(messagesData) {
      this.messages = messagesData.messages
      this.loading = false

    },


  }
}
</script>

<style scoped>

</style>
